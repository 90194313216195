@font-face {
  font-family: BasierCircle;
  font-weight: 400;
  src: url(../fonts/BasierCircle-Regular.woff);
}
@font-face {
  font-family: BasierCircle;
  font-weight: 500;
  src: url(../fonts/BasierCircle-Medium.woff);
}
@font-face {
  font-family: BasierCircle;
  font-weight: 600;
  src: url(../fonts/BasierCircle-SemiBold.woff);
}
@font-face {
  font-family: BasierCircle;
  font-weight: 700;
  src: url(../fonts/BasierCircle-Bold.woff);
}

@font-face {
  font-family: BasierCircle;
  src: url(../fonts/BasierCircle-Regular.woff);
}
@font-face {
  font-family: BasierCircle-Medium;
  src: url(../fonts/BasierCircle-Medium.woff);
}
@font-face {
  font-family: BasierCircle-SemiBold;
  src: url(../fonts/BasierCircle-SemiBold.woff);
}
@font-face {
  font-family: BasierCircle-Bold;
  src: url(../fonts/BasierCircle-Bold.woff);
}
