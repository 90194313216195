body,
html,
#app {
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #3a7fa4;
  color: #fff;
}

.player {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.player .video-container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.player .video {
  width: 100%;
  display: block;
}

.player .play-pause {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(40, 68, 80, 0.5);
  top: 0;
  opacity: 0;
  transition: 0.3s;
}

.play-pause svg:first-child {
  z-index: 1;
}

.play-pause svg:nth-child(2) {
  opacity: 0;
}

.play-pause:hover svg:nth-child(2) {
  opacity: 1;
}

.player .play-pause:hover {
  opacity: 1;
}

.player .play-pause .hide {
  opacity: 0;
  pointer-events: none;
}

.player .play-pause .button {
  width: 100px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.player .play-pause .button .button-bg {
  fill: #fff;
}

.player .play-pause .button .button-icon {
  fill: #26536b;
}

.player .seeker {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.player .seeker .seeker-line {
  position: absolute;
  width: inherit;
  height: inherit;
}

.player .seeker .seeker-line.bg {
  background-color: rgba(255, 255, 255, 0.5);
}

.player .seeker .seeker-line.mover {
  background-color: #76afce;
  transform: scaleX(0);
  transform-origin: top left;
}

.player .seeker .seeker-line.current {
  transform: scaleX(0);
  transform-origin: top left;
  background-color: #eda3a1;
}

.player .time-current,
.player .time-duration {
  position: absolute;
  bottom: 10px;
  margin: 0;
  font-size: 14px;
}

.player .time-current {
  left: 10px;
}

.player .time-duration {
  right: 10px;
}

.player .toggle-full-screen {
  position: absolute;
  margin: 0;
  right: 5px;
  top: 5px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
